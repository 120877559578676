import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const InfoBoxWrapper = styled.div`
	${mediaQuery.mobileM`
		padding-bottom: 40px;
	`}
`;

export const SectionTextBoxWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileM`
		padding-top: 20px;
	`}
`;

export const CustomerStoryWrapper = styled.div`
	${mediaQuery.tabletXS`
    	margin-top: 80px;
	`}
	${mediaQuery.mobileM`
		margin:0;
	`}
`;

export const ImgWrapper = styled.div`
	padding-top: 30px;
`;

export const CTAWrapper = styled.div`
	padding-top: 180px;
	margin-top: 50px;
	${mediaQuery.mobileM`
		padding-top: 70px;
	`}
`;
