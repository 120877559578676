import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import Customers from '../components/global/customers/Customers';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import InfoboxTwo from '../components/solution-page-template/Infobox';
import CTA from '../components/global/CTA/CTA';
import { InfoboxTwoWrapper } from '../components/live-chat-tool/styles';
import * as S from '../components/human-handover/styles';

const HumanHandover = ({ data }) => {
	const {
		section1Title,
		section1Subtitle,
		section1ButtonText,
		section1ButtonLink,
		section1Image,
		section2Title,
		section2Images,
		section3Title,
		section3Image,
		section3Content,
		section4Title,
		section4Image,
		section4Content,
		section5Title,
		section5Image,
		section5Content,
		section6Title,
		section6Subtitle,
		section6Image,
		section7Title,
		section7Subtitle,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.contentfulHumanHandover;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<S.InfoBoxWrapper>
					<InfoBox
						image={section1Image}
						title={section1Title}
						subtitle={section1Subtitle}
						buttonText={section1ButtonText}
						buttonLink={section1ButtonLink}
						hideImage="true"
						justifyContent="center"
						isHeroHeader
					/>
				</S.InfoBoxWrapper>
			</WrapperOne>
			<S.CustomerStoryWrapper>
				<Customers
					section2Images={section2Images}
					section2Title={section2Title}
				/>
			</S.CustomerStoryWrapper>
			<WrapperOne>
				<InfoboxTwoWrapper>
					<InfoboxTwo
						image={section3Image}
						title={section3Title}
						invert={true}
						content={section3Content}
					/>
				</InfoboxTwoWrapper>
				<InfoboxTwoWrapper>
					<InfoboxTwo
						image={section4Image}
						title={section4Title}
						invert={false}
						content={section4Content}
					/>
				</InfoboxTwoWrapper>
				<InfoboxTwoWrapper>
					<InfoboxTwo
						image={section5Image}
						title={section5Title}
						invert={true}
						content={section5Content}
					/>
				</InfoboxTwoWrapper>

				<S.SectionTextBoxWrapper>
					<SectionTextBox
						title={section6Title}
						subtitle={section6Subtitle}
						largeHeading="true"
					/>
				</S.SectionTextBoxWrapper>
				<S.ImgWrapper>
					<Img
						fluid={section6Image.fluid}
						alt={section6Image.title}
						style={{ maxWidth: '1000px', margin: '0 auto' }}
					/>
				</S.ImgWrapper>

				<S.CTAWrapper>
					<CTA title={section7Title} subtitle={section7Subtitle} />
				</S.CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	query {
		contentfulHumanHandover {
			section1Title
			section1Subtitle
			section1ButtonText
			section1ButtonLink
			section1Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section2Title
			section2Images {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Title
			section3Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section4Title
			section4Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section4Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section5Title
			section5Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section5Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section6Title
			section6Subtitle
			section6Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section7Title
			section7Subtitle
			seoTitle
			seoDescription
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoArticle
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;

export default HumanHandover;
